<template>
    <div id="company-config">
        <ul class="content-table">
            <li class="item">
                <p>
                    <i-switch class="switch" v-model="switch1" />
                    <span>个人资源共享到企业空间是否需要审核？</span>
                </p>
            </li>
            <li class="item">
                <Button type="primary" :loading="loading" @click="handleSave">保存</Button>
            </li>
        </ul>
    </div>  
</template>
<script>
    export default {
        name: "",
        mixins: [],
        components: {},
        props: [],
        data () {
            return {
                switch1: false,
                loading: false
            }
        },
        computed: {},
        methods: {
            handleSave() {
                this.loading = true
                this.$api.put('').then(() => {
                    this.$Message.success('成功')
                }).finally(() => {
                    this.loading =  false
                })
            },
            getData() {}
        },
        created() {}
    }
</script>
<style scoped lang="less">
#company-config {
    padding-top: 80px;
    .content-table {
        margin-top: 47px;
        padding: 0 86px;
        background: #fff;
        .item {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;
            border-bottom: 1px solid #e6e9ee;
            padding-left: 94px;
            height: 120px;
            font-size: 18px;
            &:last-child {
                border-bottom: none;
            }
            p {
                display: inline-block;
                .switch {
                    margin-right: 105px;
                }
            }
        }
    }
}
</style>